const dataAbout = [
    {
        id: 1,
        title: 'Precious and Rare',
        desc: '100+ attributes to generate 1000  shapes of 721 Soul Warriors',
        class: 'active'
    },
    {
        id: 2,
        title: 'Fair Allocation',
        desc: 'Uniform pricing, no set-aside for teams',
    },
    {
        id: 3,
        title: 'Complete Benefits',
        desc: 'Extremely valuable 8YearGangDAO platform exclusive benefits',
    },
]

export default dataAbout;