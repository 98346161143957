import img1 from '../images/common/img1.jpg'
import img2 from '../images/common/img2.jpg'
import img3 from '../images/common/img3.jpg'
import img4 from '../images/common/img4.jpg'
import img5 from '../images/common/img5.jpg'
import img6 from '../images/common/img6.jpg'
import img7 from '../images/common/img7.jpg'
import img8 from '../images/common/img8.jpg'
import img9 from '../images/common/img9.jpg'
import img10 from '../images/common/img10.jpg'
import img11 from '../images/common/img11.jpg'
import img12 from '../images/common/img12.jpg'
import img13 from '../images/common/img13.jpg'
import img14 from '../images/common/img14.jpg'
import img15 from '../images/common/img15.jpg'
import img16 from '../images/common/img16.jpg'
import img17 from '../images/common/img17.jpg'
import img18 from '../images/common/img18.jpg'
import img19 from '../images/common/img19.jpg'
import img20 from '../images/common/img20.jpg'

const dataProject = [
    {
        id: 1,
        title: 'SoulWarrior #01',
        img: img1,
    },
    {
        id: 2,
        title: 'SoulWarrior #02',
        img: img2,
    },
    {
        id: 3,
        title: 'SoulWarrior #03',
        img: img3,
    },
    {
        id: 4,
        title: 'SoulWarrior #04',
        img: img4,
    },
    {
        id: 5,
        title: 'SoulWarrior #05',
        img: img5,
    },
    {
        id: 6,
        title: 'SoulWarrior #06',
        img: img6,
    },
    {
        id: 7,
        title: 'SoulWarrior #07',
        img: img7,
    },
    {
        id: 8,
        title: 'SoulWarrior #08',
        img: img8,
    },

    {
        id: 9,
        title: 'SoulWarrior #09',
        img: img9,
    },
    {
        id: 10,
        title: 'SoulWarrior #10',
        img: img10,
    },
    {
        id: 11,
        title: 'SoulWarrior #11',
        img: img11,
    },
    {
        id: 12,
        title: 'SoulWarrior #12',
        img: img12,
    },
    {
        id: 13,
        title: 'SoulWarrior #13',
        img: img13,
    },
    {
        id: 14,
        title: 'SoulWarrior #14',
        img: img14,
    },
    {
        id: 15,
        title: 'SoulWarrior #15',
        img: img15,
    },
    {
        id: 16,
        title: 'SoulWarrior #16',
        img: img16,
    },
    {
        id: 17,
        title: 'SoulWarrior #17',
        img: img17,
    },
        {
        id: 18,
        title: 'SoulWarrior #18',
        img: img18,
    },
        {
        id: 19,
        title: 'SoulWarrior #19',
        img: img19,
    },
    {
        id: 20,
        title: 'SoulWarrior #20',
        img: img20,
    },
]

export default dataProject;