const dataRoadmap = [
    {
        id: 1,
        time: 'Q1 2023',
        list: [
            {
                text: 'Whitelist pre-warming'
            },
            {
                text: 'NFT platform Launchpad'
            },
            {
                text: 'NNS Neuron Staking'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q2 2023',
        list: [
            {
                text: 'ProQuant.xyz beta online'
            },
            {
                text: 'NNS Governance Grant'
            },
            {
                text: '8YearGangDAO node application'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q3 2023',
        list: [
            {
                text: 'ProQuant.xyz officially released'
            },
            {
                text: 'SoulArcher NFT released'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q4 2023',
        list: [
            {
                text: 'ProQuant on IC'
            },
            {
                text: '8YearGangDAO node construction and crowdfunding started'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: '2024 -',
        list: [
            {
                text: '8YearGang Node online'
            },
            {
                text: 'SoulMage launch'
            },
            {
                text: 'SoulAssassin launch'
            },
        ],
        positon: 'left'
    },
]

export default dataRoadmap;