import img1 from '../images/post/imgpost11.jpg'
import img2 from '../images/post/imgpost13.jpg'
import img3 from '../images/post/imgpost12.jpg'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: 'story',
        title: 'SoulWarrior Background',
        time: '20 March 2023',
        link:"/blog-details",
        text: 'In a land ruled by darkness and barbarism, the earth crumbles, volcanoes erupt, and the people struggle under the rule of trolls. ',
    },
    {
        id: 2,
        img : img2,
        category: 'story',
        title: 'SoulWarrior’s Web3 Dream',
        time: '20 March 2023',
        link:"/blog-details1",
        text: 'Although IC is maliciously manipulated and selectively disregarded by the capital of the old forces, Soul Warriors are still convinced of its future. ',
    },
    {
        id: 3,
        img : img3,
        category: 'story',
        title: 'About 8YearGangDAO',
        time: '20 March 2023',
        link:"/blog-details2",
        text: 'We are from the [ICP 8YearGang信仰群](https://oc.app/#/a6ew7-jyaaa-aaaaf-adsaq-cai) , the number one Chinese language community in Openchat.  ',
    },
]

export default dataBlog;