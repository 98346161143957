import bgImg1 from '../images/background/bg-1.jpg';
import bgImg2 from '../images/background/bg-2.jpg';
import bgImg3 from '../images/background/bg-3.jpg';
import img1 from '../images/common/itemslider.png';
import img2 from '../images/common/itemslider1.png';

const dataSlider = [
    {
        id: 1,
        title: 'ONLY 100% TRUE WEB3 CAN FREE OUR SOUL',
        desc : 'The capital of the old order has drained our flesh and blood, only the soul supports us to continue to fight, soul warriors fighting for freedom!',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 2,
        title: '8YEARGANG DREAM NEVER GIVE UP',
        desc : 'We belive in InternetComputer can reimagine world computer, 8YearGangdDAO commit long term to hold the dream.',
        bgImg: bgImg2,
        img : img2
    },
    {
        id: 3,
        title: 'Create value for every 8YearGang',
        desc : 'We will create unbelievable value for every DAO member leverage by all our hardwork, dedication and sacrifice.',
        bgImg: bgImg3,
        classAction: 'three'
    },
    {
        id: 4,
        title: 'CYbox nft collectionS for everyone',
        desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 5,
        title: 'CYbox nft collectionS for everyone',
        desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
        bgImg: bgImg2,
        classAction: 'two'
    },

]

export default dataSlider;