const dataFaq = [
    {
        id: 1,
        title: 'What are the NFTs?',
        content: 'NFT is 8YearGangDAO credential, which means that you have all the rights of DAO, including the copyright. It can be used as avatar to show your unique role in the IC ecosystem.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do I get NFTs?',
        content: 'We will offer it through Launchpad on the Yumi trading platform, so stay tuned for more information.',
    },
    {
        id: 3,
        title: 'How much is NFT?',
        content: 'TBD',
    },
    {
        id: 4,
        title: 'How can we buy NFT?',
        content: 'You can buy it on Yumi trading platform by having some ICP in your IC wallet (such as Plug, AstroX Me, Stoic, Internet Identity, etc.)',
    },
    {
        id: 5,
        title: 'Who are the team behind the project?',
        content: 'The team includes the backbone members of Openchat\'s ICP 8YearGang faith group, consisting of developers, designers and product operation staff.',
    },
    {
        id: 6,
        title: 'What are the rights of 8YearGangDAO Members?',
        content: 'It includes: 1. pledge neuron revenue reward; 2. ProQuant quantitative platform whitelist; 3. 8YearGang IC node machine whitelist; 4. collaborative project airdrop and whitelist.',
    },
    {
        id: 7,
        title: 'What is 8YearGangDAO?',
        content: '8YearGangDAO is an ICP 8-year pledger organization dedicated to creating value for each member and truly decentralizing.',
    },
    {
        id: 8,
        title: 'What are the rights of 8YearGangDAO Members?',
        content: 'You can become a member by holding 1 SoulWarrior NFT.',
    },
]

export default dataFaq;