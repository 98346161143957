import React from 'react';
import { Link } from 'react-router-dom';
import dataBlog from '../assets/fake-data/data-blog';
import Blog from '../features/blog/home-v2';


function BlogDetails(props) {
    return (
        <div className='post-details'>
            <section className="page-title">
                <p></p>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <div className="image">
                                    <div className="tag st2">Story</div>
                                </div>
                                <h4 className="title"><Link to="#">About 8YearGangDAO</Link></h4>
                                <ul className="meta-post">
                                    <li><span>by</span><Link to="#">Admin</Link></li>
                                    <li><Link to="#"> 20 March 2023</Link></li>
                                </ul>                     
                            </div>
                            <div className="post-details">
                                <div className="image">
                                    <img src={require('../assets/images/post/imgpost9.jpg')} alt="" />
                                </div>

                            </div>
                            <div className="content-details">
                                <h6>Who are we?</h6>
                                <p>We are from the [ICP 8YearGang信仰群](https://oc.app/#/a6ew7-jyaaa-aaaaf-adsaq-cai) , the number one community in Openchat.
We are a group of NFH (Non-Fungible Human) who love ICP, respect diversity, and encourage inward (philosophy) - outward (knowledge) exploration in both directions. </p>
                                <p>Any member who display their 8-year NNS staking screenshot can become a group administrator, and eventually the community will be completely decentralized.</p>
                                <p>name: 8yeargangDAO</p>
                                <p>neuron id: 13538714184009896865</p>
                            </div>
                            <div className="content-details">
                                <h6>keywords</h6>
                                <p>Love, Link, belong, Inclusion, Dedication, Co-creation</p>
                            </div>
                            <div className="content-details">
                                <h6>Slogan</h6>
                                <p>Create value for every ICP 8YearGang</p>
                            </div>
                            <div className="content-details">
                                <h6>What are we doing?</h6>
                                <p>We are building a spiritual home that truly belongs to the ICP 8YearGang community, and we believe that</p>
                                <ul className="list">
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Beyond making money, we are more interested in exploration new possibilities on IC;</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Share the vision of ICP and expand our community;</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Driven by curiosity rather than opportunism.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="content-details mb-41">
                                <h6>Where are we going?</h6>
                                <p>ICP 8YearGang is sowing seeds of freedom, the sunlight is fair to all, where these seeds will potentially grow towards an unimaginable shape and size.</p>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>

            <Blog data={dataBlog} />
        </div>
        
    );
}

export default BlogDetails;