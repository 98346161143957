import img1 from '../images/common/img4.jpg';
import img2 from '../images/common/img5.jpg';
import img3 from '../images/common/img6.jpg';
import img4 from '../images/common/img7.jpg';
import img5 from '../images/common/img8.jpg';
import img6 from '../images/common/team11.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Million Miles',
        position: '8YearGangDAO core member',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/MillionMiles'
            },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Joydna',
        position: '8YearGangDAO core member',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/sigermachen'
            },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Sigerma',
        position: '8YearGangDAO core member',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/hdv668'
            },
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'HDV668',
        position: 'Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ]
    },
    {
        id: 5,
        img: img5,
        name: 'Sober Dan',
        position: 'Designer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
        ]
    },
]

export default dataTeam;