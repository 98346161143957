const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'DAO interests',
        text : 'DAO governance interests (80% of sales revenue pledged for 8 years in neurons to reward DAO contributors)',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'ProQuant rights',
        text : 'Quantitative platform strategy whitelist, you will be able to use strategy trading by 50% discount.'
    },
    {
        id: 3,
        numb: '03',
        title: "ICP Node rights",
        text : '8YearGangDAO ICP Node whitelist, you will be able to participate ICP infrastructure building by Crowdfunding.'
    },
    {
        id: 4,
        numb: '04',
        title: "Airdrop and Whitelist",
        text : 'You will get priority for Cooperative project airdrop and whitelist'
    },
]
export default dataWork;