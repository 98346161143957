import React from 'react';
import { Link } from 'react-router-dom';
import dataBlog from '../assets/fake-data/data-blog';
import Blog from '../features/blog/home-v2';


function BlogDetails(props) {
    return (
        <div className='post-details'>
            <section className="page-title">
                <p></p>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <div className="image">
                                    <div className="tag st2">Story</div>
                                </div>
                                <h4 className="title"><Link to="#">Soul Warrior’s Web3 dream</Link></h4>
                                <ul className="meta-post">
                                    <li><span>by</span><Link to="#">Admin</Link></li>
                                    <li><Link to="#"> 20 March 2023</Link></li>
                                </ul>                     
                            </div>
                            <div className="post-details">
                                <div className="image">
                                    <img src={require('../assets/images/post/imgpost7.jpg')} alt="" />
                                </div>
                                <div className="content-details">
                                <p></p>
                                </div>
                                <p>Although IC is maliciously manipulated and selectively disregarded by the capital of the old forces, Soul Warriors are still convinced of its future. 8YearGang promises long-lasting loyalty, giving and acting on Web3 beliefs. Soul Warriors drive and inspire the community members to form the unique IC ecological culture.</p>
                                <div className="content-details">
                                <p></p>
                                </div>
                                <p>Soul Warriors are the incarnation of each 8YearGang, with shells, emotions, beliefs and DAO linking together. And in search of such a character and a special story of Web3 dreams, in which all are destined to participate, thus Soul Warriors is born. Soul Warriors fuse symbiotically with chimeras that do not interfere with the archetypes of human traits, an extension of Pop-style irony and wit.</p>
                                <div className="content-details">
                                <p></p>
                                </div>
                                <p>Soul Warriors’ Web3 dream is to scale team collaboration without reducing capital efficiency. No matter how huge DAO is, no value will be extracted by intermediaries or employees. As long as it remains decentralized, the code rules that reward participants based on platform contributions will continue to “reward” the right people, and we believe the value will grow by leaps and bounds.</p>
                                <div className="content-details">
                                <p></p>
                                </div>
                                <p>When gazing at the scars of Soul Warriors, you can feel their determination and fearlessness. He is not only every 8YearGang badge, but also the fearless practice of the future decentralized DAO dream. We are looking forward to your eventual encounter with your Soul Warrior’s incarnation.</p>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>

            <Blog data={dataBlog} />
        </div>
        
    );
}

export default BlogDetails;