import React from 'react';
import PageTitle from '../components/pagetitle';
import dataFaq from '../assets/fake-data/data-faq';
import AccordionItem from '../features/faq/accordion-item';
import Work from "../features/work";
import dataWork from "../assets/fake-data/data-work";


function Workpage(props) {
    return (
        <div>
            <PageTitle title='' style={{paddingBottom: 0}}/>

        <Work data={dataWork} />

        </div>
    );
}

export default Workpage;